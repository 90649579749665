
import { Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessageBox } from "element-plus";

interface Result {
  data: any;
  message: string;
}

export default class NotificationPage extends Vue {
  name = "Notification";
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  listUser: any = [];
  listAccount: any = [];
  formValidateUser: any = {
    _id: "",
    email: "",
    status: false,
  };
  dialogUser = false;
  showSetting = false;
  async created() {
    this.getList();
    this.getListAccount();
  }

  async getList() {
    let res: Result;
    await this.$store.dispatch("setLoading", true, { root: true });
    res = await axios.get("user/notification", {
      headers: this.headers,
    });
    if (res) {
      this.listUser = res.data;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async getListAccount() {
    let res: Result;
    await this.$store.dispatch("setLoading", true, { root: true });
    res = await axios.get("user/account", {
      headers: this.headers,
    });
    if (res) {
      this.listAccount = res.data;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }

  async handleDelete(id: string) {
    let res: Result;
    ElMessageBox.confirm("Do you want to delete user?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    }).then(async () => {
      res = await axios.post(
        "user/notification/delete",
        { _id: id },
        { headers: this.headers }
      );
      if (res) {
        this.$message.success(res.message);
        this.getList();
      }
    });
  }
  handleOpenModal(row: any) {
    this.formValidateUser._id = row._id;
    this.formValidateUser.email = row.email;
    this.formValidateUser.status = row.status;
    this.formValidateUser.numberOfTimeSent = 5;
    this.formValidateUser.emailExpiryValue = 0;
    this.dialogUser = true;
  }
  async handleSave() {
    const refFrorm = this?.$refs["refForm"] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;

    let res: Result;
    res = await axios.post("user/notification", this.formValidateUser, {
      headers: this.headers,
    });
    if (res) {
      this.$message.success(res.message);
      this.getList();
      this.dialogUser = false;
      this.formValidateUser = {
        email: "",
      };
    }
  }
  async update() {
    await this.$store.dispatch("setLoading", true, { root: true });
let data = {
  numberOfTimeSent: this.listAccount[0].numberOfTimeSent,
  emailExpiryValue: this.listAccount[0].emailExpiryValue,
}
    let res: Result;
    res = await axios.post("/user/setting/update-time", data, {
      headers: this.headers,
    });
    if (res) {
      this.$message.success(res.message);
      this.getList();
      this.showSetting = false;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
}
